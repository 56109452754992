<template>
    <!-- CONTENT ================================================== -->
    <div class="container-fluid">
        <div class="row justify-content-center py-5">
            <div class="col-12 col-md-10 col-xl-7 py-5">
                <div class="card shadow-sm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card-col-img" style="height:100%;background-repeat: no-repeat;background-size: contain" v-bind:style="{ 'background-image': 'url(' + school_info.banner_signin + ')' }"></div>
                        </div>
                        <div class="col-md-6">
                            <div class="card-body py-5">
                                <!-- Heading -->
                                <h1 class="display-4 text-center mb-3">
                                    <img style="width:40%" v-bind:src="school_info.header_logo">
                                </h1>
                                <!-- Subheading -->
                                <h5 class="text-muted text-center mb-5">
                                    Monitoring Kemajuan Mahasiswa
                                    <p class="mt-2" style="font-size:13px">(Portal Mahasiswa)</p>
                                </h5>
                                <!-- Form -->
                                <form v-on:submit.prevent="handleSubmit">
                                    <!-- Email address -->
                                    <div class="form-group">
                                        <!-- Label -->
                                        <label>Username</label>
                                        <!-- Input -->
                                        <input type="username" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.username.$error }"
                                            v-model="username" placeholder="Masukan username">
                                        <div v-if="submitted && !$v.username.required" class="invalid-feedback">Username
                                            is required</div>
                                    </div>
                                    <!-- Password -->
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col">

                                                <!-- Label -->
                                                <label>Password</label>
                                            </div>
                                            <div class="col-auto">
                                                <!-- Help text -->
                                                <!-- <a href="#" class="form-text small text-muted">
													Lupa kata sandi?
												</a> -->
                                            </div>
                                        </div> <!-- / .row -->
                                        <!-- Input group -->
                                        <div class="input-group input-group-merge">
                                            <!-- input-group-merge -->
                                            <!-- Input -->
                                            <!-- Input -->
                                            <input type="password" class="form-control"
                                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                v-model="password" placeholder="Masukan password">
                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                                Password is required</div>
                                            <!-- Icon -->
                                            <!-- <div class="input-group-append">
												<span class="input-group-text">
													<i class="fe fe-eye"></i>
												</span>
											</div> -->
                                        </div>
                                    </div>
                                    <!-- Submit -->
                                    <!-- Submit -->
                                    <button type="submit" class="btn btn-lg btn-block btn-primary mb-3">
                                        Login
                                    </button>

                                    <!-- Link -->
                                    <!-- <div class="text-center">
									<small class="text-muted text-center">
										Don't have an account yet? <a href="sign-up.html">Sign up</a>.
									</small>
									</div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</template>
<script>
    import axios from "axios";
    import Vue from 'vue';
    import {required} from "vuelidate/lib/validators";
    import { LoaderPlugin } from 'vue-google-login'
    import { mapState } from 'vuex'

    // google login
    Vue.use(LoaderPlugin, {
    client_id: '773024378042-flaamegoc6nc3imp1lui04sedlq6uv2e.apps.googleusercontent.com',
    scope: 'profile email',
    redirect_uri: window.location.origin
    });

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Login'
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['school_info']),
        data() {
            return {
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                const token = getUser.key;
                                if(getUser.roles == 'siswa' || getUser.roles == 'mahasiswa') {
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('progress/kemajuan');
                                } else {
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Ups!',
                                        text: "Username atau password tidak ditemukan",
                                    });
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            underMaintenace: function() {
                alert("Fitur sedang dalam maintenance");
            },
            loginGoogle() {
                Vue.GoogleAuth.then(auth2 => {
                    auth2.grantOfflineAccess().then(this.googleSignInCallback);
                });
            },
            googleSignInCallback(authResult) {
                if (authResult['code']) {
                    this.$http.post(this.$apiconfig+'website/auth/oauth2_callback', {
                        provider: 'google',
                        authorization_token: authResult['code']
                    })
                    .then(response => {
                        if (response.data.status === "success") {
                            const getUser = response.data.user;
                            const token = getUser.key;
                            localStorage.setItem('auth_token', token);
                            axios.defaults.headers.common['Authorization'] = token;
                            this.$router.push('calon-siswa/formulir');
                        } else {
                            this.$swal('Uh oh!', response.data.message, 'error')
                            console.debug("Login issue: "+response.data.message)
                        }
                    })
                    .catch(function (error) {
                        this.$swal('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: "+error)
                    });
                }
            },
        }
    }
</script>